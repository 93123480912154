import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import logo from '../images/djbilallogowhite.jpg'
import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
        <>
          <Helmet
            title={'DJ Bilal - HipHop, RnB, Garage, UK Funky and Dancehall DJ in Bristol'}
            meta={[
              { name: 'description', content: 'DJ Bilal is a Bristol based open format Club DJ, specialising in HipHop, RnB, Garage, UK Funky and Dancehall.' },
              { name: 'keywords', content: 'DJ Bilal - HipHop, RnB, Garage, UK Funky and Dancehall DJ in Bristol' },
            ]}
          >
      <html lang="en" />
      <link rel="canonical" href={"https://djbilal.com"} />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width" />
      <meta name="keywords" content={"DJ, Bristol, HipHop, RnB, Garage, UK Funky and Dancehall"} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={'DJ Bilal - HipHop, RnB, Garage, UK Funky and Dancehall DJ in Bristol'} />
      <meta property="og:description" content={'DJ Bilal is a Bristol based open format Club DJ, specialising in HipHop, RnB, Garage, UK Funky and Dancehall.'} />
      <meta property="og:image" content={"https://djbilal.com" + logo}/>
      <meta property="og:site_name" content="DJ Bilal" />
      <meta property="og:url" content={"https://djbilal.com"} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={"https://djbilal.com"} />
      <meta name="twitter:title" content={'DJ Bilal - HipHop, RnB, Garage, UK Funky and Dancehall DJ in Bristol'} />
      <meta name="twitter:description" content={'DJ Bilal is a Bristol based open format Club DJ, specialising in HipHop, RnB, Garage, UK Funky and Dancehall.'} />
      <meta name="twitter:image:src" content={"https://djbilal.com" + logo}/>
      <meta name="twitter:site" content="@deejay_bilal" />
      <meta name="robots" content="max-image-preview:large" />

          </Helmet>
          {content}
        </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
